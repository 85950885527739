<template>
  <div ref="portfolioSamples" class="portfolio-samples w-full relative">
    <div class="flex flex-row flex-wrap" :class="[`-m-${padding}`]">
      <div class="portfolio-samples__list w-full flex flex-row flex-wrap items-start" :class="[`p-${padding}`]">
        <div v-for="(sample, index) in payload" :key="sample.id" :class="[`w-1/${perRow} p-2`]">
          <SampleCard
            :class="[`portfolio-samples__sample-card__id-${sample.id}`]"
            :sample="sample"
            :vendor="sample.vendor"
            :checkbox-value="getCheckboxValueBySample(sample)"
            is-show-checkbox
            is-show-qualification-status
            :is-tooltip-left-direction="checkIsLeftTooltipDirection(index)"
            :is-sample-active="activeSample && activeSample.id === sample.id"
            :is-open-sample-view-on-click="false"
            :is-able-to-check="getIsAbleToCheckSample(sample)"
            @input="$emit('input', $event)"
            @click="$emit('click', sample)"
            @error:checkbox="showSelectedSamplesLimitError"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import SampleCard from '@/components/sample/SampleCard.vue';

export default {
  name: 'PortfolioSamplesListWithoutDraggable',
  components: {
    SampleCard,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },

    padding: {
      type: [Number, String],
      default: 4,
    },

    selectedSamples: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      perRow: 5,
      resizeObserver: null,
      previousContainerWidth: 0,
    };
  },

  computed: {
    ...mapGetters({
      activeSample: 'bulkQualification/activeSample',
      isQualificationMode: 'bulkQualification/isQualificationMode',
      selectedSamplesToQualify: 'bulkQualification/selectedSamplesToQualify',
      maxSelectedSamplesToQualify: 'bulkQualification/maxSelectedSamplesToQualify',
    }),

    routeID() {
      return this.$route.params.id;
    },
  },

  mounted() {
    this.setupResizeObserver();
  },

  beforeDestroy() {
    this.resizeObserver?.disconnect();
  },

  methods: {
    ...mapActions({
      saveOrderCollection: 'vendors/saveOrderCollection',
    }),

    getCheckboxValueBySample(sample) {
      const samplesKey = (this.isQualificationMode && 'selectedSamplesToQualify') || 'selectedSamples';

      return !!this[samplesKey].find(({ id }) => id === sample.id);
    },

    getIsAbleToCheckSample(sample) {
      return (
        (this.isQualificationMode &&
          (this.selectedSamplesToQualify.length < this.maxSelectedSamplesToQualify ||
            (this.selectedSamplesToQualify.find(({ id }) => id === sample.id) &&
              this.selectedSamplesToQualify.length === this.maxSelectedSamplesToQualify))) ||
        !this.isQualificationMode
      );
    },

    showSelectedSamplesLimitError() {
      this.$notify.error({
        title: '',
        text: this.$t('Bulk Qualification.You have reached the maximum item selection'),
      });
    },

    setupResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        entries.forEach(({ contentRect: { width } }) => {
          if (!width) {
            return;
          }

          const sampleCardWidth = (width < 768 && 150) || (width < 1250 && 180) || 200;
          const paddingX = 8 * 2;

          const perRowNext = Math.floor(width / (sampleCardWidth + paddingX));

          this.perRow = (perRowNext > 10 && 10) || (perRowNext <= 1 && 2) || perRowNext;

          if (this.previousContainerWidth !== width) {
            this.$emit('container-resized');
            this.previousContainerWidth = width;
          }
        });
      });

      this.resizeObserver.observe(this.$refs.portfolioSamples);
    },

    checkIsLeftTooltipDirection(index) {
      return (index + 1) % this.perRow === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1400px) {
  ::v-deep {
    .sample-card__vendor {
      &-avatar,
      &-avatar-component {
        $avatar-width: 33.41px;
        $avatar-height: 32px;

        width: $avatar-width;
        min-width: $avatar-width;
        max-width: $avatar-width;
        height: $avatar-height;
        min-height: $avatar-height;
        max-height: $avatar-height;
      }
    }

    .vendor {
      &-name a {
        font-size: 12px !important;
      }

      &-country {
        font-size: 11px !important;
      }
    }
  }
}
</style>
